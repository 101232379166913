<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入自提点名称"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="logo">
        <SelectImgs :selectNum="1" btnTitle="选择图片" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="info.contact_name" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="info.contact_mobile" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="省市区" prop="city">
        <el-cascader :options="cityTree" v-model="city" separator="-"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="info.address" placeholder="请输入详细地址">
          <el-button slot="append" icon="el-icon-search" @click="getLocation">地图定位</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="定位">
        <div style='position: relative;'>
          <div style="position: absolute;top: 0 !important;left: 0 !important;right: -50px !important;bottom: 0 !important;z-index: 100;cursor: pointer;" v-if='mapStatus'></div>
          <div id="container" class="container" style="width:500px; height:300px"></div>
        </div>
      </el-form-item>
      <el-form-item label="经度">
        <el-input v-model="info.longitude" placeholder="请输入经度"></el-input>
      </el-form-item>
      <el-form-item label="纬度">
        <el-input v-model="info.latitude" placeholder="请输入纬度"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
  export default {
    data() {
      return {
        id: 0,
        info: {
          title: '',
          contact_name: '',
          contact_mobile: '',
          address: '',
          img: '',
          ordering: '',
          longitude: '',
          latitude: ''
        },
        cityTree: [],
        city: [],
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入名称', trigger: 'blur' }
          ]
        },
        mapStatus: true,
        marker: false,
        map: false,
      };
    },
    components: {
      SelectImgs
    },
    created() {
      this.getCityTree()
      if(this.$route.query.id) {
        this.id = this.$route.query.id
        this.getInfo()
      }
    },
    mounted() {
      if(!this.id) {
        this.initMap(116.397469,39.908821)
      }
    },
    computed: {
    },
    methods: {
      initMap(longitude,latitude) {
        if(typeof TMap == 'undefined') {
          setTimeout(res => {
            this.initMap(longitude,latitude)
          },500)
          return false
        }
        var center = new TMap.LatLng(latitude, longitude)
        this.map = new TMap.Map("container", {
          // rotation: 20,//设置地图旋转角度
          // pitch:30, //设置俯仰角度（0~45）
          zoom:12,//设置地图缩放级别
          center: center//设置地图中心点坐标
        });
        this.map.on("click",(evt) => {
          this.info.longitude = evt.latLng.getLng().toFixed(6)
          this.info.latitude = evt.latLng.getLat().toFixed(6)
          this.initMarker(this.info.longitude,this.info.latitude)
        })
        this.initMarker(longitude,latitude)
      },
      initMarker(longitude,latitude) {
        this.map.setCenter(new TMap.LatLng(latitude, longitude));
        if(this.marker) {
          this.marker.setMap(null);
        }
        this.marker = new TMap.MultiMarker({
          id: "marker-layer", //图层id
          map: this.map,
          styles: { //点标注的相关样式
            "marker": new TMap.MarkerStyle({
              "width": 25,
              "height": 35,
            })
          },
          geometries: [{ //点标注数据数组
            // "id": "demo",
            // "styleId": "marker",
            "position": new TMap.LatLng(latitude, longitude),
            "properties": {
              "title": "marker"
            }
          }]
        });
      },
      picChoose(data) {
        this.info.img = data.pic
      },
      getCityTree() {
        this.$api.merchant.cityTree(res => {
          if(res.errcode == 0) {
            this.cityTree = res.data
          }
        })
      },
      getLocation() {
        if(this.city.length<1) {
          this.fail('请选择省市区');
          return false
        }
        if(this.info.address.length<4) {
          this.fail('请填写详细地址，不能少于4个字')
          return false
        }
        this.showLoading()
        this.$api.merchant.addressTolocation({address:this.info.address,city:this.city}, res => {
          this.hideLoading()
          if(res.errcode==0) {
            this.info.longitude = res.data.lng
            this.info.latitude = res.data.lat
            this.initMarker(this.info.longitude,this.info.latitude)
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      getInfo() {
        var that = this
        this.$api.merchant.storeDetail( {id: this.id},function (res) {
          if(res.errcode == 0) {
            that.info = res.data
            that.city = [that.info.province,that.info.city,that.info.area]
            that.initMap(that.info.longitude,that.info.latitude)
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = that.info
            param.province = that.city[0]
            param.city = that.city[1]
            param.area = that.city[2]
            this.$api.merchant.storeEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
